.waves {
    position: relative;
    width: 100%;
    height: 120px;
    overflow: hidden;
  }
  .waves .thinking {
    position: absolute;
  
    bottom: 10px;
    right: 20px;
    z-index: 100000;
  }
  
  .waves .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url('/src/images/waves.png');
    background-size: 1000px 100px;
  
  }
  
  .waves .wave.wave1 {
    animation: animate 30s linear infinite;
    z-index: 100;
    opacity: 0,5;
    animation-delay: .5s;
    bottom: 10px;
  }
  .waves .wave.wave2 {
    animation: animate2 15s linear infinite;
    z-index: 100;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
  }
  .waves .wave.wave3 {
    animation: animate 30s linear infinite;
    z-index: 100;
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15px;
  }
  .waves .wave.wave4 {
    animation: animate2 30s linear infinite;
    z-index: 100;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20px;
  }
  @keyframes animate {
    0%{
        background-position-x: 0 ;
    }
    100%{
        background-position-x: 1000px;
    }
  }
  @keyframes animate2 {
    0%{
        background-position-x: 0 ;
    }
    100%{
        background-position-x: -1000px;
    }
  }